<template>
  <div>
    <eden-page-header :title="'Production Provisions'" />
    <production-provisions />
  </div>
</template>

<script>
import ProductionProvisions from "@/components/Stewards/ProductionProvisions/ProductionProvisions";

export default {
  name: "ProductionProvisionsIndex",
  components: { ProductionProvisions },
  data() {
    return {};
  },
};
</script>
